<template>
  <div class="content">
    <div class="banner"></div>
    <div class="main with-container">
      <div class="intro">
        魔筷致力于成为商家服务领域里最被信任的引领者。魔筷重视及保护每一位知识产权权利人，以维护商家对我们的信任。<br/>根据《中华人民共和国商标法》、《中华人民共和国著作权法》、《中华人民共和国电子商务法》等法律法规的规定，特制定本政策。规范投诉流程，投诉者及被投诉者需按照本政策的指引采取措施。对于恶意投诉行为，魔筷将依据法律规定要求投诉者加倍承担赔偿责任。
      </div>
      <div class="complain" @click="gotoComplaint">
        <div class="title">
          <img class="icon" src="https://mktv-in-cdn.mockuai.com/16167531699325818.png" alt="">
          <span class="text">我要投诉</span>
        </div>
        <div class="desc">
          我认为其他经营者在魔筷平台上提供的商品／服务／资质侵犯了我的知识产权
        </div>
      </div>
      <div class="record">
        我曾发起过的投诉，<a class="link" @click.prevent="gotoRecord">查看投诉记录</a>
      </div>
      <div class="material">
        <div class="menus">
          <div
            v-for="(item, index) in materialData"
            :key="index"
            :class="current === index ? 'menu active' : 'menu'"
            @click="onMenuChange(index)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="panel">
          <div class="img">
            <img src="https://mktv-in-cdn.mockuai.com/16167531581945020.png" alt="">
          </div>
          <component :is="currentPanel"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import AcceptScope from '@/components/panels/AcceptScope.vue';
  import Complaint from '@/components/panels/Complaint.vue';
  import Appeal from '@/components/panels/Appeal.vue';
  import PenaltyRules from '@/components/panels/PenaltyRules'
  import Others from '@/components/panels/Others.vue';

  const materialData = [
    {
      title: '知识产权受理范围',
      comp: AcceptScope,
    },
    {
      title: '知识产权投诉',
      comp: Complaint,
    },
    {
      title: '知识产权申诉',
      comp: Appeal,
    },
    {
      title: '知识产权处罚规则解读',
      comp: PenaltyRules,
    },
    {
      title: '其他注意事项',
      comp: Others,
    }
  ]

  export default {
    name: 'IntellectualHome',
    // needSerialize: true,
    components: {
      AcceptScope,
      Complaint,
      Appeal,
      PenaltyRules,
      Others,
    },

    data() {
      return {
        current: 0,
        materialData,
      }
    },
    computed: {
      ...mapState([
        'isLogin',
      ]),
      currentPanel() {
        return this.materialData[this.current].comp;
      }
    },

    methods: {
      onMenuChange(index) {
        this.current = index;
      },

      gotoComplaint() {
        this.$router.push({path: '/intellectual-property/notice'});
      },

      gotoRecord() {
        this.$router.push({path: '/intellectual-property/record'});
      }
    }
  }
</script>

<style lang="less" scoped>
  .content {
    padding-bottom: 189px;
  }
  .banner {
    width: 100%;
    height: 220px;
    margin-top: 4px;
    background-image: url(https://mktv-in-cdn.mockuai.com/16167531645959022.png);
    background-size: cover;
  }
  .intro {
    box-sizing: border-box;
    margin-top: 60px;
    padding: 24px 20px;
    background-color: #f2f7ff;
    color: #333;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 16px;
    line-height: 30px;
  }
  .complain {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: 180px;
    margin-top: 32px;
    padding-top: 39px;
    background-color: #f7f8fb;
    cursor: pointer;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      .icon {
        width: 26px;
        height: 23px;
        margin-right: 16px;
      }
      .text {
        font-size: 24px;
        font-weight: 500;
        color: #333;
      }
    }
    .desc {
      line-height: 26px;
      font-size: 16px;
      color: #535457;
    }
  }
  .record {
    padding: 30px 0;
    color: #333;
    font-size: 16px;
    line-height: 22px;
    .link {
      cursor: pointer;
      color: #007cff;
    }
  }
  .material {
    display: flex;
    .menus {
      margin-right: 154px;
      .menu {
        position: relative;
        width: 240px;
        padding: 20px 0;
        font-size: 18px;
        color: #666;
        cursor: pointer;
        &.active {
          color: #ff6000;
          font-size: 20px;
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          border-radius: 1px;
          background: #ededed;
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          &::after {
            content: '';
            width: 0;
            height: 0;
          }
        }
      }
    }
    .panel {
      flex: 1;
      .img {
        width: 807px;
        height: 120px;
        margin-bottom: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>

